@font-face {
  font-family: 'SourceSansPro';
  src: url('./SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('./SourceSansPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('./SourceSansPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('./SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('./SourceSansPro-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('./SourceSansPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}
