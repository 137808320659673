@import "./component-library/constants/style.scss";

* {
  box-sizing: border-box;
}

html {
  width:100%;
  height: 100%;
  display: table;
}

body {
  width:100%;
  height: 100%;
  display:table-cell;
  font-family: $sansFont;
}

#app {
  height: 100%;
}

p, label {
  line-height: 1.4em;
}

input, textarea, keygen, select, button {
  font-family: $sansFont;
}

button {
  cursor: pointer;
  border: solid 1px $zoSuperLightGrey;

  &:focus {
    outline: 0;
  }
}

input {
  padding-left: 10px;
}

article {
  @include responsive-spacing;
  max-width: $maxWidth;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

a {
  color: $defaultLinkColor;
  text-decoration: none;
}

h2 {
  font-weight: 100;
}

.Select, .Select-control, .Select-control {
  height: 44px !important;

  &:focus {
    outline: 0;
  }

  &.is-disabled {
    background: none !important;
  }

}

.Select-value-label {
  color: $zoDarkGrey !important;
}

.Select-control {
  border-width: 0 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;

  &:hover {
    box-shadow: none;
  }

}

.Select-placeholder, .Select-value {
  top: 4px !important;
}

.StripeElement {
  background-color: white;
  padding: 8px 12px;
  border: 1px solid $zoLightGray;
  font-size: 12px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.react-toggle--checked .react-toggle-track {
  background-color: $zoBlue !important;
}
.react-toggle-thumb {
  border: 1px solid #C2C2C2 !important;
  box-shadow: 0 2px 4px 0;
}
.react-toggle-track {
  background-color: $zoWhite !important;
  border: 1px solid #C2C2C2;
}

.ReactTable {
  display: block !important;
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 1px 0 0 #d8d8d8 !important;
}

.ReactTable .rt-th {
  height: 55px;
  line-height: 45px !important;
  padding-left: 20px !important;
  text-align: left;
  background-color: #ffffff;
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 300;
}

.ReactTable {
  .-pagination .-btn {
    background-color: $zoNavyDark !important;
    color: white !important;

    &:disabled {
      background-color: $zoSuperLightGrey !important;
    }
  }
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background-color: #dcecf3 !important;
}

.ReactTable .-pagination {
  border-top: 1px solid rgba(0,0,0,0.1) !important;
  box-shadow: none !important;
  color: $zoDarkGrey !important;
}

.ReactTable .rt-td {
  padding-left: 20px !important;
  padding-right: 20px !important;
  height: 40px;
  color: #61636A;
  font-size: 14px;
}

#servicesTable {
  .ReactTable .rt-td:first-child {
    overflow: visible;
  }
  .ReactTable .rt-td:nth-child(3) {
    overflow: visible;
  }
}

.rc-time-picker-input {
  font-size: 16px;
}

.rc-time-picker-panel {
  width: 260px;
}

.rc-time-picker-panel-narrow {
  max-width: 161px;
}

.rc-time-picker-panel-inner {
  font-size: 16px;
}

.rc-time-picker-panel-select {
  width: 80px;
  overflow-x: hidden;
  font-size: 16px;
  li {
    padding: 8px 0 8px 22px;
  }
}

.rc-time-picker-panel-select:nth-child(3) {
  li {
    padding: 24px 0 24px 25px;
  }
}

.rc-time-picker-panel-select {
  max-height: 240px;
}

.rdw-link-modal {
  height: 225px !important;
}

.DateInput_input {
  text-align: center;
}

