@import "../../constants/style.scss";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.5 ease-in-out;
  visibility: visible;
  opacity: 1;
  z-index: 9999;

 &.hidden {
  visibility: hidden;
  opacity: 0;
  z-index: -10;
 }
}

.bar {
  height: 5px;
  background: $zoNavyDark;
  transition: all 300ms ease;
}

.errorWrapper {
  margin: 40px 10%;
}

.errorCard {
  margin: 0 auto;
  padding: 20px 0;
  border: 2px solid $toastRed;
  background-color: lighten($toastRed, 10%);
  text-align: center;
  color: white;
}
