/*
 * Style Constants
 */

// MAIN UI COLORS
$zoNavyDark: #20556c;
$zoDarkGrey: #575757;
$zoLightGray: #a0a0a0;
$zoSuperLightGrey: #D8D8D8;

$zoBlue: #20556C;
$zoRed: #E51500;
$zoWhite: #FFFFFF;

$toastRed: #DA5252;

$neutralGrey: #F8F8F8;

$zoMediumGray: #D8D8D8;

// ACCOUNT STATUS
$registered: #3fd615;
$invited: #ffa630;
$added: #4f6dff;
$serviceVisible: #20556C;
$serviceHidden: #F2F2F2;
$defaultLinkColor: #4486a2;

// CATGORY COLORS
$restoreGreen: #519e8a;
$styleBlue: #00107f;
$nourishRed: #eb4e32;
$arrangeBlue: #276fbf;
$careGreen: #00cb86;
$gatherOrange: #ffa630;

// COMMON LAYOUT
$maxWidth: 1440px;
$toastZ: 120;
$modalZ: 110;

// WEB APP LAYOUT only
$headerHeight: 60px;
$sideBarWidth: 280px;
$headerZ: 100;

// ENTERPRISE CMS LAYOUT only
$drawerWidth: 260px;
$drawerZ: 1000;

// Fonts
$sansFont: 'SourceSansPro', Helvetica, Arial, sans-serif;

// Themes
$themeNames: Restore, Style, Nourish, Arrange, Care, Gather;
$themeColors: $restoreGreen, $styleBlue, $nourishRed, $arrangeBlue, $careGreen, $gatherOrange;

// Mixin for inlining responsive breakpoints
@function is-number($var) {
  @if type-of($var) == number {
    @return unitless($var);
  } @else {
    @return false;
  }
}

// Horizontal media queries
@mixin break($point, $type: 'min') {
  // Target mobile
  @if $point == mobile {
    @media only screen and (min-width: 320px)  { @content ; }
  }
  // Target mobile pro
  @else if $point == mobilepro {
    @media only screen and (min-width: 480px) { @content ; }
  }
  // Target tablet
  @else if $point == tablet {
    @media only screen and (min-width: 600px) { @content ; }
  }
  // Target pro tablets
  @else if $point == tabletpro {
    @media only screen and (min-width: 840px ) { @content ; }
  }
  // Target laptops
  @else if $point == laptop {
    @media only screen and (min-width: 960px ) { @content ; }
  }
  // Target pro laptops
  @else if $point == laptoppro {
    @media only screen and (min-width: 1280px ) { @content ; }
  }
  // Target desktop
  @else if $point == desktop {
    @media only screen and (min-width: $maxWidth ) { @content ; }
  }
  // Create custom query on the fly
  @else if is-number($point) {
    @media only screen and (#{$type}-width: #{$point}px) {
    @content;
    }
  }
}
// Vertical media queries
@mixin breakHeight($point, $type: 'min') {
  @if is-number($point) {
    @media only screen and (#{$type}-height: #{$point}px) { @content ; }
  }
}

// Generic animation (for consistency)
@mixin generic-animation($prop: all) {
  transition: $prop 0.3s ease-in-out;
}

// Generic responsive spacing (for consistency)
$baseSpacing: 20px;

@mixin responsive-spacing {
  padding-left: $baseSpacing;
  padding-right: $baseSpacing;

  @include break(tabletpro) {
    padding-left: $baseSpacing * 2;
    padding-right: $baseSpacing * 2;
  }

  @include break(laptop) {
    padding-left: $baseSpacing * 3;
    padding-right: $baseSpacing * 3;
  }

}

@mixin buttonTheme($color) {
  @include generic-animation;
  background-color: $color;

  &:hover {
    background-color: darken($color, 5%);
  }
}

@mixin textButtonTheme($name, $color) {

  .#{$name} {
    color: $color;

    &:hover {
      background-color: rgba($color, 0.1);
      border-color: $color;
    }
  }

}
